const dataSources = 'data-sources';

const SELF_MEASUREMENTS = 'self-measurements';
const APPLE_HEALTH = `${dataSources}/apple-health`;
const SHARED_BUNDLES = 'shared-bundles';
const NOTIFICATION_RECEIPTS = 'notification-receipts';

export const FOLDERS = {
  SELF_MEASUREMENTS,
  SHARED_BUNDLES,
  APPLE_HEALTH,
  NOTIFICATION_RECEIPTS,
};
