import { DefaultSystemBrowserOptions, DismissStyle, SystemBrowserOptions } from '@capacitor/inappbrowser';

export const DEFAULT_OPTIONS: SystemBrowserOptions = DefaultSystemBrowserOptions;

export const CUSTOM_OPTIONS: SystemBrowserOptions = {
  ...DEFAULT_OPTIONS,
  iOS: {
    ...DEFAULT_OPTIONS.iOS,
    closeButtonText: DismissStyle.CANCEL,
  },
};
