export type Platform = 'ios' | 'android' | 'web';

export type PlatformStore = 'Apple Store' | 'Play Store';

export interface SubscriptionPayload {
  active: boolean;
  purchaseDate?: string | undefined | null;
  platform?: Platform | undefined;
}

export type PaywallPlan = 'annual' | 'month';

export type ValidateReceiptPayload = ValidateIOSReceiptPayload | ValidationAndroidPayload;

export interface ValidationAndroidPayload {
  packageName: string;
  subscriptionId: string;
  purchaseToken: string;
}

export interface ValidateIOSReceiptPayload {
  receipt: string;
}

export interface ApiValidatorBodyTransactionGoogle {
  type: 'android-playstore';
  /** Identifier of the transaction to evaluate.
   *
   * Corresponds to:
   * - the `orderId` in the receipt from Google.
   * - the `transactionId` in the receipt from Apple (or bundleID for the application receipt).
   *
   * @required */
  id: string;
  /** Google purchase token. @required */
  purchaseToken: string;
  /** Google receipt in a JSON-encoded string. @required */
  receipt: string;
  /** Google receipt signature (used to validate the local receipt). @required */
  signature: string;
}

export interface ApiValidatorBodyTransactionApple {
  /** Value `"ios-appstore"` */
  type: 'ios-appstore';
  /** Identifier of the transaction to evaluate, or set it to your application identifier if id has been set so. @required */
  id: string;
  /** Apple appstore receipt, base64 encoded. @required */
  appStoreReceipt: string;
  /**
   * Apple ios 6 transaction receipt.
   *
   * @deprecated Use `appStoreReceipt`
   */
  transactionReceipt: never;
}

export interface AppleTransaction {
  /**
   * The appAccountToken associated with this transaction.
   *
   * This field is only present if your app supplied an appAccountToken(_:) when the user made the purchase.
   */
  app_account_token?: string;
  /** The time Apple customer support canceled a transaction,
   * in a date-time format similar to the ISO 8601.
   *
   * This field is only present for refunded transactions. */
  cancellation_date?: string;
  /** The time Apple customer support canceled a transaction,
   * in UNIX epoch time format.
   *
   * https://developer.apple.com/documentation/appstorereceipts/cancellation_date_ms */
  cancellation_date_ms?: string;
  /** The reason for a refunded transaction.
   *
   * When a customer cancels a transaction, the App Store gives them a refund
   * and provides a value for this key.
   *
   * - A value of “1” indicates that the customer canceled their transaction due
   *   to an actual or perceived issue within your app.
   * - A value of “0” indicates that the transaction was canceled for another reason;
   *   for example, if the customer made the purchase accidentally.
   */
  cancellation_reason?: '0' | '1';
  /** The time a subscription expires or when it will renew,
   * in a date-time format similar to the ISO 8601. */
  expires_date?: string;
  /** The time a subscription expires or when it will renew,
   * in UNIX epoch time format, in milliseconds.
   *
   * Use this time format for processing dates.
   * https://developer.apple.com/documentation/appstorereceipts/expires_date_ms */
  expires_date_ms?: string;
  /** The time a subscription expires or when it will renew, in the Pacific Time zone. */
  expires_date_pst?: string;
  /** An indicator of whether an auto-renewable subscription is in the introductory price period.
   *
   * https://developer.apple.com/documentation/appstorereceipts/is_in_intro_offer_period */
  is_in_intro_offer_period?: 'true' | 'false';
  /** An indicator of whether a subscription is in the free trial period.
   *
   * https://developer.apple.com/documentation/appstorereceipts/is_trial_period */
  is_trial_period?: 'true' | 'false';
  /** An indicator that a subscription has been canceled due to an upgrade.
   *
   * This field is only present for upgrade transactions. */
  is_upgraded?: 'false' | 'true';
  /** Reference name of an offer code used by the user to make this transaction. */
  offer_code_ref_name?: string;
  /** The time of the original app purchase, in a date-time format similar to ISO 8601. */
  original_purchase_date: string;
  /** The time of the original app purchase, in UNIX epoch time format, in milliseconds.
   *
   * Use this time format for processing dates. For an auto-renewable subscription,
   * this value indicates the date of the subscription's initial purchase.
   * The original purchase date applies to all product types and remains the same
   * in all transactions for the same product ID.
   * This value corresponds to the original transaction’s transactionDate property
   * in StoreKit. */
  original_purchase_date_ms: string;
  /** The time of the original app purchase, in the Pacific Time zone. */
  original_purchase_date_pst: string;
  /** The transaction identifier of the original purchase.
   *
   * https://developer.apple.com/documentation/appstorereceipts/original_transaction_id */
  original_transaction_id: string;
  /** The unique identifier of the product purchased.
   *
   * You provide this value when creating the product in App Store Connect,
   * and it corresponds to the productIdentifier property of the SKPayment object
   * stored in the transaction's payment property. */
  product_id: string;
  /** The identifier of the subscription offer redeemed by the user.
   *
   * https://developer.apple.com/documentation/appstorereceipts/promotional_offer_id */
  promotional_offer_id?: string;
  /** The time the App Store charged the user's account for a purchased or restored product,
   * or the time the App Store charged the user’s account for a subscription purchase
   * or renewal after a lapse, in a date-time format similar to ISO 8601. */
  purchase_date: string;
  /** The time the App Store charged the user's account for a purchase or renewal,
   * in milliseconds since EPOCH.
   *
   * For consumable, non-consumable, and non-renewing subscription products,
   * the time the App Store charged the user's account for a purchased or restored product.
   *
   * For auto-renewable subscriptions, the time the App Store charged the user’s account
   * for a subscription purchase or renewal after a lapse.
   *
   * Use this time format for processing dates. */
  purchase_date_ms: string;
  /** The time the App Store charged the user's account for a purchase or renewal,
   * in the Pacific Time zone. */
  purchase_date_pst: string;
  /** The number of consumable products purchased.
   *
   * This value corresponds to the quantity property of the SKPayment object
   * stored in the transaction's payment property.
   *
   * The value is usually “1” unless modified with a mutable payment.
   *
   * The maximum value is 10. */
  quantity?: string;
  /** The identifier of the subscription group to which the subscription belongs.
   *
   * The value for this field is identical to the subscriptionGroupIdentifier property
   * in SKProduct. */
  subscription_group_identifier?: string;
  /** A unique identifier for a transaction such as a purchase, restore, or renewal.
   *
   * https://developer.apple.com/documentation/appstorereceipts/transaction_id */
  transaction_id: string;
  /** A unique identifier for purchase events across devices,
   * including subscription-renewal events.
   *
   * This value is the primary key for identifying subscription purchases. */
  web_order_line_item_id: string;
  /** The relationship of the user with the family-shared purchase to which they have access.
   *
   * Possible Values:
   *
   * - `FAMILY_SHARED`: The transaction belongs to a family member who benefits from service.</li>
   * - `PURCHASED`: The transaction belongs to the purchaser.</li>
   */
  in_app_ownership_type?: 'FAMILY_SHARED' | 'PURCHASED';
}

export interface SubscriptionPurchase {
  /**
   * This kind represents a subscriptionPurchase object in the androidpublisher service.
   */
  kind: 'androidpublisher#subscriptionPurchase';
  /**
   * The acknowledgement state of the subscription product. Possible values are: 0. Yet to be acknowledged 1. Acknowledged
   */
  acknowledgementState?: number | null;
  /**
   * Whether the subscription will automatically be renewed when it reaches its current expiry time.
   */
  autoRenewing?: boolean | null;
  /**
   * Time at which the subscription will be automatically resumed, in milliseconds since the Epoch. Only present if the user has requested to pause the subscription.
   */
  autoResumeTimeMillis?: string | null;
  /**
   * The reason why a subscription was canceled or is not auto-renewing. Possible values are: 0. User canceled the subscription 1. Subscription was canceled by the system, for example because of a billing problem 2. Subscription was replaced with a new subscription 3. Subscription was canceled by the developer
   */
  cancelReason?: number | null;
  /**
   * Information provided by the user when they complete the subscription cancellation flow (cancellation reason survey).
   */
  cancelSurveyResult?: SubscriptionCancelSurveyResult;
  /**
   * ISO 3166-1 alpha-2 billing country/region code of the user at the time the subscription was granted.
   */
  countryCode?: string | null;
  /**
   * A developer-specified string that contains supplemental information about an order.
   */
  developerPayload?: string | null;
  /**
   * The email address of the user when the subscription was purchased. Only present for purchases made with 'Subscribe with Google'.
   */
  emailAddress?: string | null;
  /**
   * Time at which the subscription will expire, in milliseconds since the Epoch.
   */
  expiryTimeMillis?: string | null;
  /**
   * User account identifier in the third-party service. Only present if account linking happened as part of the subscription purchase flow.
   */
  externalAccountId?: string | null;
  /**
   * The family name of the user when the subscription was purchased. Only present for purchases made with 'Subscribe with Google'.
   */
  familyName?: string | null;
  /**
   * The given name of the user when the subscription was purchased. Only present for purchases made with 'Subscribe with Google'.
   */
  givenName?: string | null;
  /**
   * Introductory price information of the subscription. This is only present when the subscription was purchased with an introductory price. This field does not indicate the subscription is currently in introductory price period.
   */
  introductoryPriceInfo?: IntroductoryPriceInfo;
  /**
   * The purchase token of the originating purchase if this subscription is one of the following: 0. Re-signup of a canceled but non-lapsed subscription 1. Upgrade/downgrade from a previous subscription For example, suppose a user originally signs up and you receive purchase token X, then the user cancels and goes through the resignup flow (before their subscription lapses) and you receive purchase token Y, and finally the user upgrades their subscription and you receive purchase token Z. If you call this API with purchase token Z, this field will be set to Y. If you call this API with purchase token Y, this field will be set to X. If you call this API with purchase token X, this field will not be set.
   */
  linkedPurchaseToken?: string | null;
  /**
   * An obfuscated version of the id that is uniquely associated with the user's account in your app. Present for the following purchases: * If account linking happened as part of the subscription purchase flow. * It was specified using https://developer.android.com/reference/com/android/billingclient/api/BillingFlowParams.Builder#setobfuscatedaccountid when the purchase was made.
   */
  obfuscatedExternalAccountId?: string | null;
  /**
   * An obfuscated version of the id that is uniquely associated with the user's profile in your app. Only present if specified using https://developer.android.com/reference/com/android/billingclient/api/BillingFlowParams.Builder#setobfuscatedprofileid when the purchase was made.
   */
  obfuscatedExternalProfileId?: string | null;
  /**
   * The order id of the latest recurring order associated with the purchase of the subscription. If the subscription was canceled because payment was declined, this will be the order id from the payment declined order.
   */
  orderId?: string | null;
  /**
   * The payment state of the subscription. Possible values are: 0. Payment pending 1. Payment received 2. Free trial 3. Pending deferred upgrade/downgrade Not present for canceled, expired subscriptions.
   */
  paymentState?: number | null;
  /**
   * Price of the subscription, For tax exclusive countries, the price doesn't include tax. For tax inclusive countries, the price includes tax. Price is expressed in micro-units, where 1,000,000 micro-units represents one unit of the currency. For example, if the subscription price is €1.99, price_amount_micros is 1990000.
   */
  priceAmountMicros?: string | null;
  /**
   * The latest price change information available. This is present only when there is an upcoming price change for the subscription yet to be applied. Once the subscription renews with the new price or the subscription is canceled, no price change information will be returned.
   */
  priceChange?: SubscriptionPriceChange;
  /**
   * ISO 4217 currency code for the subscription price. For example, if the price is specified in British pounds sterling, price_currency_code is "GBP".
   */
  priceCurrencyCode?: string | null;
  /**
   * The Google profile id of the user when the subscription was purchased. Only present for purchases made with 'Subscribe with Google'.
   */
  profileId?: string | null;
  /**
   * The profile name of the user when the subscription was purchased. Only present for purchases made with 'Subscribe with Google'.
   */
  profileName?: string | null;
  /**
   * The promotion code applied on this purchase. This field is only set if a vanity code promotion is applied when the subscription was purchased.
   */
  promotionCode?: string | null;
  /**
   * The type of promotion applied on this purchase. This field is only set if a promotion is applied when the subscription was purchased. Possible values are: 0. One time code 1. Vanity code
   */
  promotionType?: number | null;
  /**
   * The type of purchase of the subscription. This field is only set if this purchase was not made using the standard in-app billing flow. Possible values are: 0. Test (i.e. purchased from a license testing account) 1. Promo (i.e. purchased using a promo code)
   */
  purchaseType?: number | null;
  /**
   * Time at which the subscription was granted, in milliseconds since the Epoch.
   */
  startTimeMillis?: string | null;
  /**
   * The time at which the subscription was canceled by the user, in milliseconds since the epoch. Only present if cancelReason is 0.
   */
  userCancellationTimeMillis?: string | null;
}

export interface SubscriptionCancelSurveyResult {
  /**
   * The cancellation reason the user chose in the survey. Possible values are: 0. Other 1. I don't use this service enough 2. Technical issues 3. Cost-related reasons 4. I found a better app
   */
  cancelSurveyReason?: number | null;
  /**
   * The customized input cancel reason from the user. Only present when cancelReason is 0.
   */
  userInputCancelReason?: string | null;
}

export interface IntroductoryPriceInfo {
  /**
   * Introductory price of the subscription, not including tax. The currency is the same as price_currency_code. Price is expressed in micro-units, where 1,000,000 micro-units represents one unit of the currency. For example, if the subscription price is €1.99, price_amount_micros is 1990000.
   */
  introductoryPriceAmountMicros?: string | null;
  /**
   * ISO 4217 currency code for the introductory subscription price. For example, if the price is specified in British pounds sterling, price_currency_code is "GBP".
   */
  introductoryPriceCurrencyCode?: string | null;
  /**
   * The number of billing period to offer introductory pricing.
   */
  introductoryPriceCycles?: number | null;
  /**
   * Introductory price period, specified in ISO 8601 format. Common values are (but not limited to) "P1W" (one week), "P1M" (one month), "P3M" (three months), "P6M" (six months), and "P1Y" (one year).
   */
  introductoryPricePeriod?: string | null;
}

export interface SubscriptionPriceChange {
  /**
   * The new price the subscription will renew with if the price change is accepted by the user.
   */
  newPrice?: Price;
  /**
   * The current state of the price change. Possible values are: 0. Outstanding: State for a pending price change waiting for the user to agree. In this state, you can optionally seek confirmation from the user using the In-App API. 1. Accepted: State for an accepted price change that the subscription will renew with unless it's canceled. The price change takes effect on a future date when the subscription renews. Note that the change might not occur when the subscription is renewed next.
   */
  state?: number | null;
}

export interface Price {
  /**
   * 3 letter Currency code, as defined by ISO 4217. See java/com/google/common/money/CurrencyCode.java
   */
  currency?: string | null;
  /**
   * Price in 1/million of the currency base unit, represented as a string.
   */
  priceMicros?: string | null;
}

export interface AndroidPurchaseData {
  platform: 'android';
  receipt: SubscriptionPurchase;
}

export type PurchaseData = {
  transaction: SubscriptionPurchase | AppleTransaction;
};

export type VerifyReceiptStatus = 'Verifying' | 'Verified' | 'Failed' | 'Initial';
export type SubscriptionPurchaseStatus = 'Ordering' | 'Ordered' | 'Failed' | 'Initial';

/**
 * Possible states of a transaction.
 *
 * ```
 * INITIATED → PENDING (optional) → APPROVED → FINISHED
 * ```
 */
export enum TransactionState {
  INITIATED = 'initiated',
  PENDING = 'pending',
  APPROVED = 'approved',
  CANCELLED = 'cancelled',
  FINISHED = 'finished',
  UNKNOWN_STATE = '',
}

export interface TransactionActionPayload {
  transactionState: TransactionState;
  transactionId?: string;
  productId?: string;
  offerId?: string;
  purchaseDate?: string;
}

export interface SubscriptionPurchaseActionPayload {
  productId?: string;
  offerId?: string;
  error?: CdvPurchase.IError;
}

export function createTransactionActionPayload(transaction: CdvPurchase.Transaction): TransactionActionPayload {
  return {
    transactionId: transaction.transactionId,
    transactionState: transaction.state,
    purchaseDate: transaction.purchaseDate?.toISOString(),
    productId: transaction.products.map((product) => product.id).join(','),
  };
}
