import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PlatformStore } from '../models';

/*
 * Check if the platform is iOS
 */
export const isIOS = () => {
  return Capacitor.getPlatform() === 'ios';
};

/*
 * Check if the platform is Android
 */
export const isAndroid = () => {
  return Capacitor.getPlatform() === 'android';
};

/*
 * The identifier of the app. On iOS it's the Bundle Identifier. On Android it's the Application ID
 */
export const packageId = async () => {
  const appInfo = await App.getInfo();
  return appInfo.id;
};

export const platformStore: { [key: string]: PlatformStore | undefined } = {
  ios: 'Apple Store',
  android: 'Play Store',
};
