import { Injectable, signal, Signal } from '@angular/core';
import { IModalStep } from './modal-step.interface';
import { ModalStepperComponent } from './modal-stepper.component';

@Injectable()
export class ModalStepService {
  private stepper: ModalStepperComponent | undefined;

  private storage: IModalStep[] = [];
  private currentIndex = 0;

  private _currentStep = signal<IModalStep | null>(null);
  public currentStep: Signal<IModalStep | null> = this._currentStep;

  setStepper(stepper: ModalStepperComponent) {
    this.stepper = stepper;
  }

  push(item: IModalStep): void {
    this.storage.push(item);

    // If a timing issue is causing the step to be pushed after
    // the index has been updated, set the current step (again)
    if (this.storage.length - 1 === this.currentIndex) {
      this._currentStep.set(item);
    }
  }

  first() {
    this.currentIndex = 0;
    this._currentStep.set(this.storage[this.currentIndex]);
  }

  previous() {
    this.currentIndex--;
    this._currentStep.set(this.storage[this.currentIndex]);
  }

  next() {
    this.currentIndex++;
    this._currentStep.set(this.storage[this.currentIndex]);
  }

  canGoBack(): boolean {
    return this.currentIndex > 0;
  }

  close() {
    if (this.stepper) {
      this.stepper.close('closed by service');
    }
  }

  remove(step: IModalStep) {
    const index = this.storage.indexOf(step);
    if (index > -1) {
      this.storage.splice(index, 1);
    }
  }
}
