import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Signal,
  SkipSelf,
  TemplateRef,
  ViewChild,
  computed,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IModalStep } from './modal-step.interface';
import { ModalStepService } from './modal-step.service';

@Component({
  standalone: true,
  selector: 'app-modal-stepper',
  imports: [CommonModule, NgbModalModule, MatIconModule],
  templateUrl: './modal-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // When nesting modal steps, use the same service to get to the deepest
    // nesting happens when using the component outlet feature of the modal step
    // this makes it possible to get the reference to the inner header, body, footer
    // templates using the service
    {
      provide: ModalStepService,
      useFactory: (parentService: ModalStepService) => parentService || new ModalStepService(),
      deps: [[new Optional(), new SkipSelf(), ModalStepService]],
    },
  ],
})
export class ModalStepperComponent implements AfterContentInit {
  @Input() autoOpen = false;
  @ViewChild('modal') private modal!: TemplateRef<any>;
  private modalService = inject(NgbModal);
  public service = inject(ModalStepService);

  public currentStep: Signal<IModalStep | null> = this.service.currentStep;

  private openedModal?: NgbModalRef = undefined;

  headerToRender: Signal<TemplateRef<any> | null> = computed(() => this.currentStep()?.getHeader() ?? null);
  bodyToRender: Signal<TemplateRef<any> | null> = computed(() => this.currentStep()?.getBody() ?? null);
  footerToRender: Signal<TemplateRef<any> | null> = computed(() => this.currentStep()?.getFooter() ?? null);

  constructor() {
    this.service.setStepper(this);
  }

  get canGoBack() {
    return this.service.canGoBack();
  }

  open() {
    if (this.openedModal !== undefined) {
      this.close('opened other');
    }

    setTimeout(() => {
      this.service.first();
      this.openedModal = this.modalService.open(this.modal, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'modal-stepper',
        backdrop: 'static', // Prevent closing by clicking outside of the modal
        keyboard: false, // Prevent closing by pressing the escape key
        scrollable: true,
        modalDialogClass: 'modal-dialog-bottom-sm',
      });
    });
  }

  close(reason?: string) {
    if (this.openedModal) {
      this.openedModal.close(reason ?? 'closed');
      this.openedModal = undefined;
    }
  }

  previous() {
    this.service.previous();
  }

  next() {
    this.service.next();
  }

  ngAfterContentInit() {
    if (this.autoOpen) {
      this.open();
    }
  }
}
