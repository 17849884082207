import { ObservationStorage } from '@globals/constants';
import { Identifier, Observation } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';

export type SourceType = 'Apple Health' | 'Self measurement' | 'Healthcare provider';

const sourceFolders: Record<string, string> = {
  'Apple Health': ObservationStorage.FOLDERS.APPLE_HEALTH,
  'Self measurement': ObservationStorage.FOLDERS.SELF_MEASUREMENTS,
};

function getStorageFolderBySystemCode(systemCodes: string[]): string {
  const sourceType: SourceType = getSourceTypeBySystemCode(systemCodes);
  const folder: string = sourceFolders[sourceType];

  if (!folder) {
    throw new Error('No storage folder found for the given source type');
  }

  return folder;
}

function getSourceTypeBySystemCode(systemCodes: string[]): SourceType {
  for (const code of systemCodes) {
    if (code === CodeSystems.APPLE_HEALTH) {
      return 'Apple Health';
    } else if (code === `${CodeSystems.DIGI_ME}.1.1.1`) {
      return 'Self measurement';
    }
  }

  return 'Healthcare provider';
}

function getSystemCodes(observation: Observation): string[] {
  return observation
    .identifier!.map((identifier: Identifier) => identifier.system)
    .filter((system): system is string => !!system);
}

export function getSourceType(observation: Observation): SourceType {
  const systemCodes: string[] = getSystemCodes(observation);
  return getSourceTypeBySystemCode(systemCodes);
}

export function getStorageFolder(observation: Observation): string {
  const systemCodes: string[] = getSystemCodes(observation);
  return getStorageFolderBySystemCode(systemCodes);
}
