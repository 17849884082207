import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Svg } from '@globals';
import { Badge } from './badge.model';

@Component({
  standalone: true,
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styles: [
    `
      .badge-icon {
        width: 24px;
        height: 24px;
      }

      :host ::ng-deep .section-badge-font * {
        font-size: 12px !important;
      }
    `,
  ],
  imports: [CommonModule],
})
export class BadgeComponent {
  @Input({ required: true }) badge!: Badge | null;

  get svgDefaultPath(): string {
    return Svg.defaultPath;
  }
}
