export class Badge {
  label?: string;
  code?: SeverityLevel;
  color?: string;
  icon?: string;
  textColor?: string;
}

export enum SeverityLevel {
  Fatal = 1,
  Severe = 2,
  Low = 3,
}
