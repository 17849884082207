import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@capacitor/inappbrowser';
import { BrowserOptions } from '@globals/constants';

/**
 * Closes embedded browser when on iOS.
 */
export async function closeEmbeddedBrowser(): Promise<void> {
  if (Capacitor.getPlatform() === 'ios') {
    try {
      await InAppBrowser.close();
    } catch {
      // Do nothing
    }
  }
}

/**
 * Opens the embedded browser.
 *
 * For iOS the DismissStyle.CANCEL is set.
 *
 * @param url the url to open in the browser
 */
export async function openEmbeddedBrowser(url: string): Promise<void> {
  await InAppBrowser.openInSystemBrowser({
    url: url,
    options: Capacitor.getPlatform() == 'ios' ? BrowserOptions.CUSTOM_OPTIONS : BrowserOptions.DEFAULT_OPTIONS,
  });
}
