import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { IModalStep } from './modal-step.interface';
import { ModalStepService } from './modal-step.service';

@Component({
  standalone: true,
  selector: 'app-modal-step',
  imports: [CommonModule],
  template: `<ng-template #header><ng-content select="app-modal-step-header"></ng-content></ng-template>
    <ng-template #body><ng-content select="app-modal-step-body"></ng-content></ng-template>
    <ng-template #footer><ng-content select="app-modal-step-footer"></ng-content></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // When nesting modal steps, use the same service to get to the deepest
    // nesting happens when using the component outlet feature of the modal step
    // this makes it possible to get the reference to the inner header, body, footer
    // templates using the service
    {
      provide: ModalStepService,
      useFactory: (parentService: ModalStepService) => parentService || new ModalStepService(),
      deps: [[new Optional(), new SkipSelf(), ModalStepService]],
    },
  ],
})
export class ModalStepComponent implements OnInit, OnDestroy, IModalStep {
  @ViewChild('header') header!: TemplateRef<any>;
  @ViewChild('body') body!: TemplateRef<any>;
  @ViewChild('footer') footer!: TemplateRef<any>;

  private readonly service = inject(ModalStepService);

  ngOnDestroy(): void {
    this.service.remove(this);
  }

  ngOnInit(): void {
    this.service.push(this);
  }

  getHeader(): TemplateRef<any> {
    return this.header;
  }

  getBody(): TemplateRef<any> {
    return this.body;
  }

  getFooter(): TemplateRef<any> {
    return this.footer;
  }
}
